import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // we init with resources
        resources: {
            "en": {
                "translations": {
                    "home": "Home",
                    "home_desc": "Developing web & mobile applications",
                    "contact_us": "Contact Us",
                    "what_we_do": "What We Do?",
                    "our_projects": "Our Projects",
                    "all_services": "Our Services",
                    "services_title1": "Web & App Design",
                    "services_title2": "Mobile Development",
                    "services_title3": "Web Development",
                    "services_title4": "Project Management",
                    "services_title5": "E-Commerce Solutions",
                    "services_title6": "Server & Cloud Solutions",
                    "services_desc1": "We design your application or website from scratch.",
                    "services_desc2": "We create your application on iOS and Android platforms.",
                    "services_desc3": "We provide responsive and high-speed website development.",
                    "services_desc4": "We maintain and develop your IT project with our professional team.",
                    "services_desc5": "We move your store to online world.",
                    "services_desc6": "We provide scalable and reliable IT solutions for your business.",
                    "happy_customers_title": "10+ Happy Customers",
                    "happy_customers_desc": "Customer satisfaction is critical for us.",
                    "satisfaction_count": "100%",
                    "satisfaction_title": "Satisfaction",
                    "users_per_month_count": "250K+",
                    "users_per_month_title": "Product Users Per Month",
                    "lines_of_code_count": "2M+",
                    "lines_of_code_title": "Lines Of Code",
                    "name_surname": "Name / Surname",
                    "contact_us_desc": "Please fill following form to contact us",
                    "email": "Email",
                    "message": "Message",
                    "satisfaction": "Satisfaction",
                    "product_users_per_month": "Monthly Active Users",
                    "lines_of_code": "Lines of Code",
                    "submit":"Submit",
                    "message_success": "Thank you for your interest. We'll reply asap.",
                    "go_to_project": "Go to Project",
                    "happy_customers_happy_users": "Happy Customers, Happy Users",
                    "buyrun_desc": "We are trying to make the best QR Menu for restaurants and hotels! Special recommendations for customers, automatic translation into different languages, payment at the table, product labeling, discount definition and more are available at BuyRun!"
                }
            },
            "tr": {
                "translations": {
                    "home_desc": "Hem müşterilerimiz için hem de kendi projelerimiz için web & mobil uygulama geliştirme yapıyoruz.",
                    "contact_us": "Bize Ulaşın",
                    "what_we_do": "Ne Yapıyoruz?",
                    "our_projects": "Projelerimiz",
                    "all_services": "Hizmetlerimiz",
                    "services_title1": "Web & Uygulama Tasarımı",
                    "services_title2": "Mobil Geliştirme",
                    "services_title3": "Web Geliştirme",
                    "services_title4": "Proje Yönetimi",
                    "services_title5": "E-Ticaret Çözümleri",
                    "services_title6": "Sunucu & Bulut Çözümleri",
                    "services_desc1": "Uygulamanızı veya web sitenizi sıfırdan tasarlıyoruz.",
                    "services_desc2": "Uygulamanızı iOS ve Android platformlarında oluşturuyoruz.",
                    "services_desc3": "Duyarlı ve yüksek hızda web sitesi geliştirme sunuyoruz.",
                    "services_desc4": "IT projenizi profesyonel ekibimizle yönetiyor ve geliştiriyoruz.",
                    "services_desc5": "Mağazanızı çevrimiçi dünyaya taşıyoruz.",
                    "services_desc6": "İşletmeniz için ölçeklenebilir ve güvenilir IT çözümleri sağlıyoruz.",
                    "happy_customers_title": "10+ Mutlu Müşteri",
                    "happy_customers_desc": "Müşteri memnuniyeti bizim için kritik öneme sahiptir.",
                    "satisfaction_count": "%100",
                    "satisfaction_title": "Memnuniyet",
                    "users_per_month_count": "Ayda 250K+",
                    "users_per_month_title": "Aylık Ürün Kullanıcıları",
                    "lines_of_code_count": "2M+",
                    "lines_of_code_title": "Kod Satırı",
                    "name_surname": "Ad / Soyad",
                    "contact_us_desc": "Bize ulaşmak için lütfen aşağıdaki formu doldurun",
                    "email": "E-posta",
                    "message": "Mesaj",
                    "satisfaction": "Memnuniyet",
                    "product_users_per_month": "Aylık Aktif Kullanıcı",
                    "lines_of_code": "Satır Kod",
                    "submit":"Gönder",
                    "message_success": "İlginiz için teşekkürler, en kısa zamanda geri döneceğiz.",
                    "go_to_project": "Projeye Git",
                    "happy_customers_happy_users": "Mutlu Müşteriler, Mutlu Kullanıcılar",
                    "buyrun_desc": "Restoran ve oteller için en iyi QR Menüyü yapmaya çalışıyoruz! Müşterilere özel öneriler, farklı dillere otomatik çevirme, siparişi masada ödeme, ürün etiketleme, indirim tanımlama ve daha fazlası BuyRun’da!"
                }
            }
        },
        fallbackLng: "en",
        debug: true,

        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
