import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from './utils/SectionProps';
import Input from './components/elements/Input';
import emailjs from 'emailjs-com'
import { useTranslation } from 'react-i18next';


const propTypes = {
    ...SectionProps.types,
    split: PropTypes.bool
}

const defaultProps = {
    ...SectionProps.defaults,
    split: false
}

const Cta = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    split,
    ...props
}) => {


    const [isSent, setIsSent] = useState(false);
    const form = useRef();
    const {t} = useTranslation();

    const handleSubmit = (e) => {
        console.log("form", form);
        e.preventDefault()
        emailjs
            .sendForm(
                'service_3qm25hj', 'template_cifpjda', form.current, 'V8yu8CBnJVAjTXNow'
            )
            .then(
                (result) => {
                    setIsSent(true)
                    console.log(result.text)
                },
                (error) => {
                    console.log(error.text)
                }
            )
    }

    const outerClasses = classNames(
        'cta section center-content-mobile reveal-from-bottom',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'cta-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider',
        split && 'cta-split'
    );

    return (
        <div className=''>
            <div className="container">
                {isSent ? <p>{t('message_success')}</p> :

                    <form className="form-inline" ref={form}>
                        <div className='row'>

                            <div className="form-group mb-2 col-sm-6">
                                <label htmlFor="staticEmail2" className="sr-only">{t('name_surname')}</label>
                                <Input id="name" name="name" type="name" label="" className="form-control" labelHidden style={{ marginBottom: "20px" }} />
                            </div>
                            <div className="form-group mb-2 col-sm-6">
                                <label htmlFor="staticEmail2" className="sr-only">{t('email')}</label>
                                <Input id="email" name="email" type="name" label="" className="form-control" labelHidden style={{ marginBottom: "20px" }} />
                            </div>
                            <div className="form-group mb-2 col-sm-12">
                                <label htmlFor="inputPassword2" className="sr-only">{t('message')}</label>
                                <textarea className="form-control" style={{ marginBottom: "20px", width: "100%" }} name="message"></textarea>
                            </div>
                            <div className='col-sm-12'>
                                <button type="submit" className="btn btn-primary mb-2" onClick={handleSubmit} style={{backgroundColor: "#4664a5", borderColor: "#4664a5"}}>{t('submit')}</button>
                            </div>
                        </div>
                    </form>
                }
            </div>
            </div>
    );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;