import logo from './logo.svg';
import { GrContact, GrInstagram, GrLinkedin } from 'react-icons/gr';
import { BiCart, BiCodeAlt, BiMobile, BiSolidPaintRoll, BiSolidServer } from 'react-icons/bi';
import { AiOutlineFundProjectionScreen } from 'react-icons/ai';
import './App.css';
import { useEffect, useState } from 'react';
import Cta from './Contact';
import { useTranslation } from 'react-i18next';
import Select from "react-select";
import { FaBars, FaExternalLinkAlt, FaLink, FaTimes } from "react-icons/fa";

const Layout = () => {
  const date = new Date();
  const { t, i18n } = useTranslation();
  const [navOpenMobile, setNavOpenMobile] = useState(false);

  const langs = [
    { value: "tr", label: "🇹🇷 TR" },
    { value: "en", label: "🇬🇧 EN" },
  ]

  const selectedLang = langs.find(lang => i18n.language.indexOf(lang.value) !== -1)

  const [lang, setSelectedLang] = useState(selectedLang);

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  const onSelectLanguage = (e) => {
    setSelectedLang(e);
    changeLanguage(e.value)
    setTimeout(function () {
      window.location.reload()
    }, 1000)
  }
  return (
    <>
      <header className="header navbar-area">
        <div className="container">
          
          <div className="row align-items-center">

            <div className="col-lg-12">
              <div className="nav-inner">
                {/* Start Navbar */}
                <nav className="navbar navbar-expand-lg">
                  <a className="navbar-brand" href="index.html">
                    <img src="/images/logo/logo.svg" alt="Codinto" />
                  </a>
                  <button onClick={() => {setNavOpenMobile(!navOpenMobile)}} className="navbar-toggler mobile-menu-btn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    {!navOpenMobile ? 
                    <><FaBars size={25} className='text-white'></FaBars>
                    </> : <> <FaTimes size="25" className='text-white'></FaTimes> </>}
                  </button>
                  <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                    <ul id="nav" className="navbar-nav ms-auto" >
                      <li className="nav-item">
                        <a href="#what-we-do" className="page-scroll" aria-label="Toggle navigation">{t('what_we_do')}</a>
                      </li>
                      <li className="nav-item">
                        <a href="#our-projects" className="page-scroll">{t('our_projects')}</a>
                      </li>
                      <li className="nav-item">
                        <a href="#contactus" aria-label="Toggle navigation">{t('contact_us')}</a>

                      </li>

                      <li className="nav-item" >
                        <div style={{ marginTop: "-65px" }}>
                          <Select
                            options={langs}
                            onChange={onSelectLanguage}
                            value={
                              langs.filter(option =>
                                option.value === lang.value)
                            }
                            isMultiple={false}
                          />
                        </div>

                      </li>
                    </ul>
                  </div>
                  {/* <div className="button add-list-button">
                  <a href="javascript:void(0)" className="btn">Get it now</a>
                </div> */}
                </nav>
                {/* End Navbar */}
              </div>
            </div>
          </div> {/* row */}
        </div> {/* container */}
      </header>
      {navOpenMobile &&
      <div className="container">
        <div className='row align-items-center'>
          <div className='col-sm-12 mt-80'>
          <ul id="nav" className="nav navbar-nav navbar-nav-mobile relative nav-mobile">
              <li className="nav-item">
                <a href="#what-we-do" className="page-scroll" style={{fontSize: "1.2em"}}>{t('what_we_do')}</a>
              </li>
              <li className="nav-item">
                <a href="#our-projects" className="page-scroll" style={{fontSize: "1.2em"}}>{t('our_projects')}</a>
              </li>
              <li className="nav-item">
                <a href="#contactus" style={{fontSize: "1.2em"}}>{t('contact_us')}</a>
              </li>

              <li className="nav-item" style={{width: "120px"}} >
                <div>
                  <Select
                    options={langs}
                    onChange={onSelectLanguage}
                    value={
                      langs.filter(option =>
                        option.value === lang.value)
                    }
                    isMultiple={false}
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
            </div>}
            
      {/* End Header Area */}
      {/* Start Hero Area */}
      <section id="home" className="hero-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12 col-12">
              <div className="hero-content">
                <h1 className="wow fadeInLeft" data-wow-delay=".4s">"We deploy code into life"</h1>
                <p className="wow fadeInLeft" data-wow-delay=".6s">{t('home_desc')}</p>
                <div className="button wow fadeInLeft" data-wow-delay=".8s">
                  <a href="#contactus" className="btn"><GrContact /> {t('contact_us')}</a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-12">
              <div className="hero-image wow fadeInRight" data-wow-delay=".4s">
                <img src="/images/hero/development.png" alt="#" style={{ width: "80%" }} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Hero Area */}

      <section id="what-we-do" className="features section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h3 className="wow zoomIn" data-wow-delay=".2s">{t('all_services')}</h3>
                <h2 className="wow fadeInUp" data-wow-delay=".4s">{t('what_we_do')}
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".6s"></p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              {/* Start Single Feature */}
              <div className="single-feature wow fadeInUp" data-wow-delay=".2s">
                <i><BiSolidPaintRoll /></i>
                <h3>{t('services_title1')}</h3>
                <p>{t('services_desc1')}</p>
              </div>
              {/* End Single Feature */}
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              {/* Start Single Feature */}
              <div className="single-feature wow fadeInUp" data-wow-delay=".4s">
                <i><BiMobile></BiMobile></i>
                <h3>{t('services_title2')}</h3>
                <p>{t('services_desc2')}</p>
              </div>
              {/* End Single Feature */}
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              {/* Start Single Feature */}
              <div className="single-feature wow fadeInUp" data-wow-delay=".6s">
                <i><BiCodeAlt></BiCodeAlt></i>
                <h3>{t('services_title3')}</h3>
                <p>{t('services_desc3')}</p>
              </div>
              {/* End Single Feature */}
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              {/* Start Single Feature */}
              <div className="single-feature wow fadeInUp" data-wow-delay=".2s">
                <i><AiOutlineFundProjectionScreen /></i>
                <h3>{t('services_title4')}</h3>
                <p>{t('services_desc4')}</p>
              </div>
              {/* End Single Feature */}
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              {/* Start Single Feature */}
              <div className="single-feature wow fadeInUp" data-wow-delay=".4s">
                <i><BiCart></BiCart></i>
                <h3>{t('services_title5')}</h3>
                <p>{t('services_desc5')}</p>
              </div>
              {/* End Single Feature */}
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              {/* Start Single Feature */}
              <div className="single-feature wow fadeInUp" data-wow-delay=".6s">
                <i><BiSolidServer></BiSolidServer></i>
                <h3>{t('services_title6')}</h3>
                <p>{t('services_desc6')}</p>
              </div>
              {/* End Single Feature */}
            </div>
          </div>
        </div>
      </section>


      {/* Start Features Area */}
      <section id="our-projects" className="features-buyrun bg-white section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h2 className="wow fadeInUp" data-wow-delay=".4s">{t('our_projects')}
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".6s"></p>
              </div>
            </div>
          </div>


          <div className="white-box" style={{width: "80%", margin: "0 auto"}}>

          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-12 d-flex">
             <img src="https://buyrun.com/images/login/buyrun-logo.svg" width={300} className='mr-20'></img>
            </div>
            <div className="col-lg-7 col-md-7 col-sm-12">
              <p style={{fontSize: "1.2em"}}>{t('buyrun_desc')}</p>
              <div className="button wow fadeInLeft mt-10" data-wow-delay=".8s" >
                  <a href="https://buyrun.com" target='_blank' className="btn" style={{backgroundColor: "#af3015", color: "#fff", marginTop: "10px"}}>
                    <FaExternalLinkAlt size={15} className='mr-10'/> {t('go_to_project')}</a>
                </div>
            </div>
            

          </div>
          </div>
        </div>
      </section>
      {/* End Features Area */}
      {/* Start Achievement Area */}
      <section className="our-achievement section">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1 col-md-12 col-12">
              <div className="title">
                <h2>{t('happy_customers_happy_users')}</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 offset-lg-2 col-md-12 col-12">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-12">
                  <div className="single-achievement wow fadeInUp" data-wow-delay=".2s">
                    <h3 className="counter"><span id="secondo1" className="countup" cup-end={100}>100</span>%</h3>
                    <p>{t('satisfaction')}</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12">
                  <div className="single-achievement wow fadeInUp" data-wow-delay=".4s">
                    <h3 className="counter"><span id="secondo2" className="countup" cup-end={120}>250K+</span></h3>
                    <p>{t('product_users_per_month')}</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12">
                  <div className="single-achievement wow fadeInUp" data-wow-delay=".6s">
                    <h3 className="counter"><span id="secondo3" className="countup" cup-end={125}>2M</span>+</h3>
                    <p>{t('lines_of_code')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="contactus" className="features section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h2 className="wow fadeInUp" data-wow-delay=".4s">{t('contact_us')}
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".6s">{t('contact_us_desc')}</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className='d-flex align-items-center justify-content-center'>
              <div className="col-lg-6 col-md-6 col-sm-12">

                <Cta split />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Achievement Area */}
      {/* Start Pricing Table Area */}
      {/* <section id="pricing" className="pricing-table section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h3 className="wow zoomIn" data-wow-delay=".2s">pricing</h3>
                <h2 className="wow fadeInUp" data-wow-delay=".4s">Pricing Plan</h2>
                <p className="wow fadeInUp" data-wow-delay=".6s">There are many variations of passages of Lorem
                  Ipsum available, but the majority have suffered alteration in some form.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-12">
              <div className="single-table wow fadeInUp" data-wow-delay=".2s">
                <div className="table-head">
                  <h4 className="title">Hobby</h4>
                  <p>All the basics for starting a new business</p>
                  <div className="price">
                    <h2 className="amount">$12<span className="duration">/mo</span></h2>
                  </div>
                  <div className="button">
                    <a href="javascript:void(0)" className="btn">Buy Hobby</a>
                  </div>
                </div>
                <div className="table-content">
                  <h4 className="middle-title">What's Included</h4>
                  <ul className="table-list">
                    <li><i className="lni lni-checkmark-circle" /> Cras justo odio.</li>
                    <li><i className="lni lni-checkmark-circle" /> Dapibus ac facilisis in.</li>
                    <li><i className="lni lni-checkmark-circle" /> Morbi leo risus.</li>
                    <li><i className="lni lni-checkmark-circle" /> Potenti felis, in cras ligula.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="single-table wow fadeInUp" data-wow-delay=".4s">
                <div className="table-head">
                  <h4 className="title">Freelancer</h4>
                  <p>All the basics for starting a new business</p>
                  <div className="price">
                    <h2 className="amount">$24<span className="duration">/mo</span></h2>
                  </div>
                  <div className="button">
                    <a href="javascript:void(0)" className="btn">Buy Freelancer</a>
                  </div>
                </div>
                <div className="table-content">
                  <h4 className="middle-title">What's Included</h4>
                  <ul className="table-list">
                    <li><i className="lni lni-checkmark-circle" /> Cras justo odio.</li>
                    <li><i className="lni lni-checkmark-circle" /> Dapibus ac facilisis in.</li>
                    <li><i className="lni lni-checkmark-circle" /> Morbi leo risus.</li>
                    <li><i className="lni lni-checkmark-circle" /> Potenti felis, in cras ligula.</li>
                  </ul>
                </div>
              </div>
            </div>
            
        
          </div>
        </div>
      </section> */}
      {/*/ End Pricing Table Area */}
      {/* Start Call To Action Area */}

      {/* End Call To Action Area */}
      {/* Start Footer Area */}
      <footer className="footer">
        {/* Start Footer Top */}
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-12">
                {/* Single Widget */}
                <div className="single-footer f-about">
                  <div className="logo">
                    <a href="index.html">
                      <img src="/images/logo/logo.svg" alt="#" />
                    </a>
                  </div>
                  <ul className="social">
                    <li><a href="https://linkedin.com/company/codintocom" target='_blank'><i style={{ fontSize: "16pt" }}><GrLinkedin ></GrLinkedin></i></a></li>
                    <li><a href="https://instagram.com/codintocom" target='_blank'><i style={{ fontSize: "16pt" }}><GrInstagram></GrInstagram></i></a></li>
                  </ul>
                  <p className="copyright-text">&copy;{date.getFullYear()} | CODINTO BİLİŞİM ANONİM ŞİRKETİ</p>
                </div>
                {/* End Single Widget */}
              </div>
              <div className="col-lg-8 col-md-8 col-12">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-12">
                    {/* Single Widget */}
                    <div className="single-footer f-link">
                      {/* <h3>Solutions</h3>
                      <ul>
                        <li><a href="javascript:void(0)">Marketing</a></li>
                        <li><a href="javascript:void(0)">Analytics</a></li>
                        <li><a href="javascript:void(0)">Commerce</a></li>
                        <li><a href="javascript:void(0)">Insights</a></li>
                        <li><a href="javascript:void(0)">Promotion</a></li>
                      </ul> */}
                    </div>
                    {/* End Single Widget */}
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                    {/* Single Widget */}
                    <div className="single-footer f-link">
                      {/* <h3>Support</h3>
                      <ul>
                        <li><a href="javascript:void(0)">Pricing</a></li>
                        <li><a href="javascript:void(0)">Documentation</a></li>
                        <li><a href="javascript:void(0)">Guides</a></li>
                        <li><a href="javascript:void(0)">API Status</a></li>
                        <li><a href="javascript:void(0)">Live Support</a></li>
                      </ul> */}
                    </div>
                    {/* End Single Widget */}
                  </div>
                  <div className="col-lg-2 col-md-6 col-12">
                    {/* Single Widget */}
                    <div className="single-footer f-link">
                      {/* <h3>Company</h3>
                      <ul>
                        <li><a href="javascript:void(0)">About Us</a></li>
                        <li><a href="javascript:void(0)">Our Blog</a></li>
                        <li><a href="javascript:void(0)">Jobs</a></li>
                        <li><a href="javascript:void(0)">Press</a></li>
                        <li><a href="javascript:void(0)">Contact Us</a></li>
                      </ul> */}
                    </div>
                    {/* End Single Widget */}
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    {/* Single Widget */}
                    <div className="single-footer f-link">
                      <h3>{t('contact_us')}</h3>
                      <ul>
                        <li><img src="/images/hero/infoemail.png" /></li>
                        <li><a href="javascript:void(0)">Kızılırmak Mah. Dumlupınar Bulvarı <br />No:3C1-160 Çankaya/Ankara</a></li>
                        <li><a href="tel:+903126662360">+90 (312) 666 23 60</a></li>
                      </ul>
                    </div>
                    {/* End Single Widget */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*/ End Footer Top */}
      </footer>
    </>
  )
}

function App() {

  return (

    <div>
      <Layout></Layout>

    </div>
  );
}

export default App;
